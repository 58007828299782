import $ from 'jquery';
import slick from 'slick-carousel';

function slide () {

    if ($('.js-slider').length) {
        $('.js-slider').slick({
            autoplay: false,
            slidesToShow: 3,
            arrows: true,
            loop: false,
            focusOnSelect: true,
            prevArrow: '<button type="button" class="slick-prev"><img class="svg" src="assets/images/arrow.svg" /></button>',
            nextArrow: '<button type="button" class="slick-next"><img class="svg" src="assets/images/arrow.svg" /></i></button>',
            responsive: [
                {
                    breakpoint: 1278,
                    settings: {
                        slidesToShow: 2
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1
                    }
                }
            ]
        });
    }

}

export {slide};